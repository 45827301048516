.offer-form-container {
  max-width: 900px;
  margin: auto;
  padding: 20px;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  font-family: "Roboto", sans-serif;
  color: #333;
}

.offer-form-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.logo {
  height: 50px;
  margin-right: 20px;
}

.offer-form {
  padding: 20px;
}

.offer-form h3 {
  margin-bottom: 15px;
  color: #ca932c;
  font-size: 22px;
  font-weight: 600;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
  text-align: left;
}

.offer-form input[type="text"],
.offer-form input[type="file"] {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

.offer-form input[type="text"]:focus,
.offer-form input[type="file"]:focus {
  border-color: #0d6efd;
}

.offer-form input[type="checkbox"] {
  margin-right: 10px;
}

.offer-form label {
  display: block;
  margin: 5px 0;
}

.offer-form .payment-plan,
.offer-form .layout-entry {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.offer-form .payment-plan input[type="text"],
.offer-form .layout-entry input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: #f9f9f9;
}

.offer-form .layout-entry input[type="file"] {
  width: auto;
  padding: 5px;
}

.offer-form button {
  padding: 12px 20px;
  background-color: #0d6efd;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.offer-form button:hover {
  background-color: #0056b3;
}

.basic-multi-select,
.basic-single-select {
  width: 100%;
  margin-bottom: 20px;
}

.basic-single-select .select__control,
.basic-multi-select .select__control {
  border-radius: 5px;
  font-size: 16px;
  border-color: #ccc;
  transition: border-color 0.3s ease;
}

.basic-single-select .select__control--is-focused,
.basic-multi-select .select__control--is-focused {
  border-color: #0d6efd;
  box-shadow: 0 0 0 1px #0d6efd;
}

.basic-single-select .select__menu,
.basic-multi-select .select__menu {
  border-radius: 5px;
  z-index: 5;
}

@media (max-width: 600px) {
  .offer-form-container {
    padding: 15px;
  }

  .offer-form .payment-plan input[type="text"],
  .offer-form .layout-entry input[type="text"] {
    width: 100%;
    margin-bottom: 10px;
  }

  .offer-form .payment-plan,
  .offer-form .layout-entry {
    flex-direction: column;
  }

  .offer-form button {
    width: 100%;
  }
}
